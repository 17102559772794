@mixin checkbox-configs {
  @include checkbox-tokens;
  @include checkbox-default;
  @include checkbox-dark-mode;
}

@mixin checkbox-tokens {
  --checkbox-padding: var(--spacing-scale-base);
  --checkbox-size: 24px;
}

@mixin checkbox-default {
  display: block;
  line-height: 0px;

  // Texto
  label {
    color: var(--color);
    cursor: pointer;
    display: inline-block;
    font-weight: var(--font-weight-medium);
    line-height: 1.6em;
    min-height: var(--checkbox-size);
    padding-left: calc(var(--checkbox-size) + var(--checkbox-padding));
    position: relative;

    &:empty {
      padding-left: var(--checkbox-size);
    }
  }

  &.hidden-label {
    label {
      padding-left: calc(var(--checkbox-size) + var(--surface-width-md) * 2);
      text-indent: -10000px;
      white-space: nowrap;
      width: 0;
    }
  }

  &.d-inline {
    label {
      padding-right: v(spacing-scale-4x);
    }
  }

  input {
    // Remover o checkbox padrão do navegador
    opacity: 0;
    position: absolute;

    &:focus-visible,
    &.focus-visible {
      &:checked + label::before,
      + label::before {
        @include focus-soft;
      }
    }
    //hover
    &:hover:not(:disabled) {
      + label::before {
        --interactive-rgb: var(--interactive-light-rgb);
        background-image: linear-gradient(
          rgba(var(--interactive-rgb), var(--hover)),
          rgba(var(--interactive-rgb), var(--hover))
        );
      }
    }

    &:disabled + label {
      @include disabled;

      &:hover::before {
        background-image: none;
      }
    }

    // Caixa
    + label {
      align-items: flex-start;
      cursor: pointer;
      display: inline-block;
      font-size: v(font-size-scale-base);
      font-weight: v(font-weight-medium);
      min-height: v(spacing-scale-3x);
      // padding: v(spacing-scale-half) v(spacing-scale-4x) v(spacing-scale-base)
      // v(spacing-scale-4x);
      position: relative;

      &::before {
        background: var(--background-light);
        border-color: var(--border-color);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        content: "";
        height: var(--checkbox-size);
        left: 0;
        position: absolute;
        width: var(--checkbox-size);
      }

      &:empty {
        padding-left: v(spacing-scale-3x);
      }
    }

    &[indeterminate] {
      color: var(--color-d);
      + label {
        &::before {
          --interactive-rgb: var(--color-rgb);
          background: var(--selected);
          border-color: var(--selected);
        }
      }
      &:hover:not(:disabled) {
        + label::before {
          --interactive-rgb: var(--color-dark-rgb);
        }
      }
    }
    // Checado
    &:checked + label::after {
      border: solid var(--selected);
      border-width: 0 3px 3px 0;
      content: "";
      height: v(icon-size-sm);
      left: 8px;
      position: absolute;
      top: 4px;
      transform: rotate(45deg);
      width: 8px;
    }

    &:checked {
      &[indeterminate] + label::after {
        border-color: var(--background-light);
        border-width: 0 0 3px;
        top: 2px;
        transform: none;
      }
    }
    // Inválido
    &:invalid {
      + label::before {
        --border-color: var(--danger);
        border-width: 2px;
      }
    }
    // Desativado
    &:disabled + label::before {
      box-shadow: none;
    }
  }

  // Versão pequena
  &.is-small,
  &.small,
  &[small] {
    input[type="checkbox"] {
      // Texto
      + label {
        line-height: v(spacing-scale-2xh);
        min-height: v(spacing-scale-2xh);
      }

      // Caixa
      + label::before {
        height: v(spacing-scale-2xh);
        width: v(spacing-scale-2xh);
      }

      // Checado
      &:checked + label::after {
        border-width: 0 2px 2px 0;
        height: v(icon-size-sm);
        left: 7px;
        top: 6px;
        width: 6px;
      }

      &:checked {
        &[indeterminate] + label::after {
          border-color: var(--background-light);
          border-width: 0 0 3px;
          top: 2px;
          transform: none;
        }
      }
    }
  }
  @each $state, $color in (invalid, danger), (valid, success) {
    &.is-#{$state}:not(.is-inverted):not(.inverted):not([inverted]),
    &.#{$state}:not(.is-inverted):not(.inverted):not([inverted]),
    &[#{$state}]:not(.is-inverted):not(.inverted):not([inverted]) {
      input[type="checkbox"] {
        + label::before {
          --border-color: var(--#{$color});
          border-width: 2px;
        }

        &:focus-visible,
        &.focus-visible {
          &:checked + label::before,
          + label::before {
            --border-color: var(--focus-color);
          }
        }
        // Checado
        &:checked + label::before {
          --border-color: var(--#{$color});
        }
      }
    }
  }
}

@mixin checkbox-dark-mode {
  &.inverted,
  &.dark-mode {
    &,
    label {
      color: var(--color-dark);
    }
  }
}
